var menuOpen = false;
var burgerBtn = document.querySelector(".burger-menu");
let navElement = document.getElementById("#navigation");

burgerBtn.addEventListener("click", function () {
  burgerBtn.classList.toggle("change");
  document.querySelector("body").classList.toggle("mobile-menu-open");
});

jQuery(window).on('load', function () {
  let textarea = document.getElementById("g-recaptcha-response-100000");
  textarea.setAttribute("aria-hidden", "true");
  textarea.setAttribute("aria-label", "do not use");
  textarea.setAttribute("aria-readonly", "true");
});

jQuery(document).ready(function(){
  jQuery("img").each(function() {
    var img = jQuery(this);
    if (!img.attr("alt") || img.attr("alt") === "")
        img.attr("alt", "car");
    });
 });

 jQuery(document).ready(function(){
  jQuery(".xdsoft_prev").append("<span style='display:none'>text</span>");
  jQuery(".xdsoft_next").append("<span style='display:none'>text</span>");
  jQuery(".xdsoft_today_button").append("<span style='display:none'>text</span>");
  jQuery(".who-we-are .flexy .left-side h3").append("<span style='display:none'>text</span>");
  jQuery(".page-id-55 .hero_title h1").append("<span style='display:none'>text</span>");
  jQuery(".vrcdialog-overlay-close").append("<span style='display:none'>text</span>");
  jQuery(".page-id-92 .why-us h2").append("<span style='display:none'>text</span>");
  jQuery(".page-id-92 .who-we-are h2").append("<span style='display:none'>text</span>");
 });

 