var body = document.querySelector("body");
if (body.classList.contains("home")) {
  document.querySelectorAll(".car_separator").forEach((e) => e.remove()); //this must leave

  jQuery(document).ready(function ($) {
    $(".vrc-search-results-block").slick({
      slidesToShow: 3, //so this can work properly
      dots: false,
      centerMode: true,
      autoplay: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            centerMode: true /* set centerMode to false to show complete slide instead of 3 */,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 512,
          settings: {
            slidesToShow: 1,
            centerMode: true /* set centerMode to false to show complete slide instead of 3 */,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });
}
